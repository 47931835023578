<template>
  <div>
    <nav-header @developmentchangedata="developmenthandler"></nav-header>
    <div class="envCon" :class="lang">
      <div class="development">
        <div class="news feature">
          <h4 style="padding-bottom: 0">{{$t('development.title')}}</h4>

        </div>
      </div>
    </div>
  </div>


</template>

<script>

import NavHeader from '../components/Header'
import {mapState} from 'vuex'
import { getdataInfo } from "../api/home";
export default {
  name: "Media",
  components:{
    NavHeader
  },
  data() {
    return{
      id:6,
      pageType: 'media',
      envideoList:[
      ],
      zh_data:{},
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
      date:''
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },
  computed:{
    ...mapState({
      lang:state =>state.lang
    })
  },
  //query: {content: JSON.stringify(this.zh_data.newLists[index])} // 参数传值
  methods: {
    toDetail (index) {
      this.$router.push({
        name: 'detail',
        path:'/detail',
        query:{id:index}
      })
    },

    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      zh_envdata.newLists = zh_envdata.newLists.reverse()
      const en_envdata= JSON.parse(data.esgContent.enContent)
      en_envdata.newLists=en_envdata.newLists.reverse()
      if(this.$i18n.locale=='zh'){
        this.zh_data = zh_envdata
      }else {
        this.zh_data = en_envdata
      }
    },

    developmenthandler(){
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">
.development{
  text-align: center;
}

.development h4{
  font-size: 36px;
  color:rgb(34, 195, 173);
  font-weight: bold;

  padding-bottom: 30px;
}


</style>
